import React, { useEffect, useState } from "react";
import { fetchMedtrackMocks } from "../../../../processes/mockProccess";
import { CheckIfIsLoading } from "../../CheckIfIsLoading";
import { Skeleton } from "../../components";
import { StartMedtrackScreen } from "./StartMedtrack";
import { WeeklyStudyTimeScreen } from "./WeeklyStudyTime";

export function FirstAccessScreen({
  onNextStep,
  onChangeAvailableStudyTime,
  availableStudyTime,
  isBases,
  isRevision,
  activeMedtrackType,
  isFirstAccess,
}) {
  const [medtrackExams, setMedtrackExams] = useState(undefined);

  const isLoadingMedtrackExams = medtrackExams === undefined;
  const hasMedtrackExams =
    medtrackExams !== undefined && medtrackExams?.length !== 0;
  const hasUnfinishedExam = medtrackExams?.some((exam) => exam.progress !== 1);

  useEffect(() => {
    if (!medtrackExams) {
      fetchMedtrackMocks({ activeMedtrackType }).then((response) => {
        setMedtrackExams(response);
      });
    }
  }, [medtrackExams]);

  return (
    <CheckIfIsLoading
      isLoading={isLoadingMedtrackExams}
      placeholder={<Skeleton />}
    >
      {hasMedtrackExams && hasUnfinishedExam ? (
        <StartMedtrackScreen
          isBases={isBases}
          medtrackExamsResponse={medtrackExams}
          activeMedtrackType={activeMedtrackType}
        />
      ) : (
        <WeeklyStudyTimeScreen
          isRevision={isRevision}
          isBases={isBases}
          onNextStep={onNextStep}
          onChangeAvailableStudyTime={onChangeAvailableStudyTime}
          availableStudyTime={availableStudyTime}
          isFirstAccess={isFirstAccess}
        />
      )}
    </CheckIfIsLoading>
  );
}
