import * as Dialog from "@radix-ui/react-dialog";
import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from "react";
import { IoClose, IoInformationCircleSharp } from "react-icons/io5";
import { Button } from "semantic-ui-react";
import {
  notifyNotSubmitSucceeded,
  notifySubmitSucceeded
} from "../../processes/notifierProcesses";
import { AlertCircleOutline } from "../AlertCircleOutline";
import { Divider } from "../Divider";
import { RevisionBox } from "../RevisionBox";
import { Tooltip } from "../Tooltip";
import prepareRevisions from "./prepareRevisions";
import setReviewsFields from "./setReviewsFields";
import {
  ButtonContainer,
  CancelButton,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogTitle,
  Header,
  ReviewContainer,
  ReviewText,
  RevisionBoxContainer,
  SubjectDescription,
  SubjectTag,
  SuggestionRelevanceContainer,
  Title,
  TooltipContainer
} from "./styles";

export const ModalReview = ({
  dispatch,
  onClose,
  data,
  setButtonDisabled,
  reviewType
}) => {
  const [revisions, setRevisions] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false,
    seventh: false,
    eight: false
  });
  const [isSavingRevision, setIsSavingRevision] = useState(false);

  const reviewTypeMock = {
    REVIEW: {
      dateRange: [
        {
          number: 7,
          text: "first"
        },
        {
          number: 30,
          text: "second"
        },
        {
          number: 60,
          text: "third"
        }
      ]
    },
    B2BORBASES: {
      dateRange: [
        {
          number: 7,
          text: "first"
        },
        {
          number: 14,
          text: "second"
        },
        {
          number: 60,
          text: "third"
        }
        // {
        //   number: 60,
        //   text: "fourth"
        // }
      ]
    },
    EXTENSIVE: {
      dateRange: [
        {
          number: 7,
          text: "first"
        },
        {
          number: 14,
          text: "second"
        },
        {
          number: 30,
          text: "third"
        },
        {
          number: 60,
          text: "fourth"
        },
        {
          number: 90,
          text: "fifth"
        },
        {
          number: 120,
          text: "sixth"
        }
        // {
        //   number: 240,
        //   text: "seventh"
        // },
        // {
        //   number: 360,
        //   text: "eight"
        // }
      ]
    }
  };

  const formRef = useRef(null);

  const getStatus = () => {
    if (data.module.relevance == "LOW") return "Baixo impacto";
    if (data.module.relevance == "MEDIUM") return "Médio impacto";
    if (data.module.relevance == "HIGH") return "Alto impacto";
  };

  const getMessage = () => {
    if (status === "Alto impacto") {
      if (reviewType === "REVIEW")
        return "Esse tema é considerado de alto impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 3 revisões).";
      if (reviewType === "B2BORBASES")
        return "Esse tema é considerado de alto impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 3 revisões).";
      if (reviewType === "EXTENSIVE")
        return "Esse tema é considerado de alto impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 5 a 6 revisões).";
    }
    if (status === "Médio impacto") {
      if (reviewType === "REVIEW")
        return "Esse tema é considerado de médio impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 2 revisões).";
      if (reviewType === "B2BORBASES")
        return "Esse tema é considerado de médio impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 2 revisões).";
      if (reviewType === "EXTENSIVE")
        return "Esse tema é considerado de médio impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 3 a 4 revisões).";
    }
    if (status === "Baixo impacto") {
      if (reviewType === "REVIEW")
        return "Esse tema é considerado de baixo impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 1 revisão).";
      if (reviewType === "B2BORBASES")
        return "Esse tema é considerado de baixo impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 1 revisão).";
      if (reviewType === "EXTENSIVE")
        return "Esse tema é considerado de baixo impacto para seu resultado. Sugerimos caprichar nessas revisões (ideal: 1 a 2 revisões).";
    }
  };

  useEffect(() => {
    if (data) setRevisions(setReviewsFields(data));
  }, [data]);

  const status = getStatus();
  const message = getMessage();
  const title = data.module.name.split(" - ")[0];
  const handleSubmit = () => {
    const session = JSON.parse(localStorage.getItem("session"));
    const userId = session.currentUserId;

    const postBody = prepareRevisions(revisions);
    postBody["user_id"] = userId;
    postBody["platform_level_id"] = data.module.id;
    postBody["review_type"] = reviewType;

    setIsSavingRevision(true);

    fetch(`${process.env.METRICOAPI_URL}revisions/createOrUpdateRevision`, {
      method: "POST",
      body: JSON.stringify(postBody),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.text())
      .then(() => {
        notifySubmitSucceeded(dispatch, "Revisão cadastrada com sucesso!");
        setButtonDisabled(true);
        onClose();
      })
      .catch((err) => notifyNotSubmitSucceeded(dispatch, err))
      .finally(() => {
        setIsSavingRevision(false);
      });
  };
  return (
    <Dialog.Portal>
      <DialogOverlay>
        <DialogContent>
          <Header>
            <DialogTitle>
              <h2>Agendar revisão personalizada</h2>
              <TooltipContainer>
                <IoInformationCircleSharp />
                <Tooltip>
                  Ao agendar suas revisões personalizadas, você foca no que vai
                  te trazer mais resultado, de acordo com a relevância para suas
                  bancas e seu desempenho nesses temas.
                </Tooltip>
              </TooltipContainer>
            </DialogTitle>

            <DialogClose asChild>
              <IoClose size={32} />
            </DialogClose>
          </Header>

          <Divider mb={"24px"} maxMobileMb={"16px"} />

          <Title>
            Deseja criar uma revisão personalizada para a aula de{" "}
            <span>{title}</span> ?
          </Title>

          <SuggestionRelevanceContainer mode={status}>
            <SubjectTag mode={status}>{status}</SubjectTag>
            <SubjectDescription>
              <AlertCircleOutline color={status} />
              <p>{message}</p>
            </SubjectDescription>
          </SuggestionRelevanceContainer>

          <ReviewContainer>
            <ReviewText>
              Selecione abaixo as revisões que deseja realizar, baseado nos
              intervalos (em dias) que seguem a curva de esquecimento. Fique a
              vontade para editar ou utilizar o padrão já estabelecido:
            </ReviewText>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              onChange={() => setRevisions(formRef?.current?.getData())}
            >
              <RevisionBoxContainer isReviewType={reviewType === "REVIEW"}>
                {reviewTypeMock[reviewType].dateRange.map((date, index) => {
                  return (
                    <RevisionBox
                      revision={date.text}
                      order={index + 1}
                      day={date.number}
                      checked={revisions[date.text]}
                    />
                  );
                })}
              </RevisionBoxContainer>

              <Divider />
              <ButtonContainer>
                <CancelButton asChild>
                  <Button onClick={onClose} secondary className="cancelButton">
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      Cancelar
                    </span>
                  </Button>
                </CancelButton>
                <Button
                  primary
                  className="confirmButton"
                  disabled={isSavingRevision || !Object.values(revisions).includes(true)}
                >
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    Agendar revisões
                  </span>
                </Button>
              </ButtonContainer>
            </Form>
          </ReviewContainer>
        </DialogContent>
      </DialogOverlay>
    </Dialog.Portal>
  );
};
